import React, { useEffect, useState } from 'react'
import db from "../firebase"
import { getStorage, ref, uploadBytesResumable, getDownloadURL, getMetadata } from "firebase/storage";
import { collection, onSnapshot, addDoc, getDocs, getDoc, doc, updateDoc, arrayUnion } from 'firebase/firestore';
import { Link } from 'react-router-dom';

//---------------------

// {/* <div className="col-lg-3 col-md-4 col-sm-6">
// <div className="product__item">
//     <div
//         className="product__item__pic set-bg"
//         style={{
//             backgroundImage: `url(${product1})`,
//         }}
//     >
//         <div className="label new">New</div>
//         {/* <ul className="product__hover">
// <li>
// <a href="img/product/related/rp-1.jpg" className="image-popup"
// ><span className="arrow_expand"></span
// ></a>
// </li>
// <li>
// <a href="#"><span className="icon_heart_alt"></span></a>
// </li>
// <li>
// <a href="#"><span className="icon_bag_alt"></span></a>
// </li>
// </ul> */}
//     </div>
//     <div className="product__item__text">
//         <h6><a href="#">Buttons tweed blazer</a></h6>

//         <div className="product__price">₹ 59.0</div>
//     </div>
// </div>
// </div>
// <div className="col-lg-3 col-md-4 col-sm-6">
// <div className="product__item">
//     <div
//         className="product__item__pic set-bg"
//         style={{
//             backgroundImage: `url(${product2})`,
//         }}
//     >
//         {/* <ul className="product__hover">
// <li>
// <a href="img/product/related/rp-2.jpg" className="image-popup"
// ><span className="arrow_expand"></span
// ></a>
// </li>
// <li>
// <a href="#"><span className="icon_heart_alt"></span></a>
// </li>
// <li>
// <a href="#"><span className="icon_bag_alt"></span></a>
// </li>
// </ul> */}
//     </div>
//     <div className="product__item__text">
//         <h6><a href="#">Flowy striped skirt</a></h6>

//         <div className="product__price">₹ 49.0</div>
//     </div>
// </div>
// </div>
// <div className="col-lg-3 col-md-4 col-sm-6">
// <div className="product__item">
//     <div
//         className="product__item__pic set-bg"
//         style={{
//             backgroundImage: `url(${product3})`,
//         }}
//     >
//         <div className="label stockout">out of stock</div>
//         {/* <ul className="product__hover">
// <li>
// <a href="img/product/related/rp-3.jpg" className="image-popup"
// ><span className="arrow_expand"></span
// ></a>
// </li>
// <li>
// <a href="#"><span className="icon_heart_alt"></span></a>
// </li>
// <li>
// <a href="#"><span className="icon_bag_alt"></span></a>
// </li>
// </ul> */}
//     </div>
//     <div className="product__item__text">
//         <h6><a href="#">Cotton T-Shirt</a></h6>

//         <div className="product__price">₹ 59.0</div>
//     </div>
// </div>
// </div>
// <div className="col-lg-3 col-md-4 col-sm-6">
// <div className="product__item">
//     <div
//         className="product__item__pic set-bg"
//         style={{
//             backgroundImage: `url(${product4})`,
//         }}
//     >
//         {/* <ul className="product__hover">
// <li>
// <a href="img/product/related/rp-4.jpg" className="image-popup"
// ><span className="arrow_expand"></span
// ></a>
// </li>
// <li>
// <a href="#"><span className="icon_heart_alt"></span></a>
// </li>
// <li>
// <a href="#"><span className="icon_bag_alt"></span></a>
// </li>
// </ul> */}
//     </div>
//     <div className="product__item__text">
//         <h6><a href="#">Slim striped pocket shirt</a></h6>

//         <div className="product__price">₹ 59.0</div>
//     </div>
// </div>
// </div>
// <div className="col-lg-3 col-md-4 col-sm-6">
// <div className="product__item">
//     <div
//         className="product__item__pic set-bg"
//         style={{
//             backgroundImage: `url(${product5})`,
//         }}
//     >
//         <div className="label new">New</div>
//         {/* <ul className="product__hover">
// <li>
// <a href="img/product/related/rp-1.jpg" className="image-popup"
// ><span className="arrow_expand"></span
// ></a>
// </li>
// <li>
// <a href="#"><span className="icon_heart_alt"></span></a>
// </li>
// <li>
// <a href="#"><span className="icon_bag_alt"></span></a>
// </li>
// </ul> */}
//     </div>
//     <div className="product__item__text">
//         <h6><a href="#">Buttons tweed blazer</a></h6>

//         <div className="product__price">₹ 59.0</div>
//     </div>
// </div>
// </div>
// <div className="col-lg-3 col-md-4 col-sm-6">
// <div className="product__item">
//     <div
//         className="product__item__pic set-bg"
//         style={{
//             backgroundImage: `url(${product6})`,
//         }}
//     >
//         {/* <ul className="product__hover">
// <li>
// <a href="img/product/related/rp-2.jpg" className="image-popup"
// ><span className="arrow_expand"></span
// ></a>
// </li>
// <li>
// <a href="#"><span className="icon_heart_alt"></span></a>
// </li>
// <li>
// <a href="#"><span className="icon_bag_alt"></span></a>
// </li>
// </ul> */}
//     </div>
//     <div className="product__item__text">
//         <h6><a href="#">Flowy striped skirt</a></h6>

//         <div className="product__price">₹ 49.0</div>
//     </div>
// </div>
// </div>
// <div className="col-lg-3 col-md-4 col-sm-6">
// <div className="product__item">
//     <div
//         className="product__item__pic set-bg"
//         style={{
//             backgroundImage: `url(${product7})`,
//         }}
//     >
//         <div className="label stockout">out of stock</div>
//         {/* <ul className="product__hover">
// <li>
// <a href="img/product/related/rp-3.jpg" className="image-popup"
// ><span className="arrow_expand"></span
// ></a>
// </li>
// <li>
// <a href="#"><span className="icon_heart_alt"></span></a>
// </li>
// <li>
// <a href="#"><span className="icon_bag_alt"></span></a>
// </li>
// </ul> */}
//     </div>
//     <div className="product__item__text">
//         <h6><a href="#">Cotton T-Shirt</a></h6>

//         <div className="product__price">₹ 59.0</div>
//     </div>
// </div>
// </div>
// <div className="col-lg-3 col-md-4 col-sm-6">
// <div className="product__item">
//     <div
//         className="product__item__pic set-bg"
//         style={{
//             backgroundImage: `url(${product8})`,
//         }}
//     >
//         {/* <ul className="product__hover">
// <li>
// <a href="img/product/related/rp-4.jpg" className="image-popup"
// ><span className="arrow_expand"></span
// ></a>
// </li>
// <li>
// <a href="#"><span className="icon_heart_alt"></span></a>
// </li>
// <li>
// <a href="#"><span className="icon_bag_alt"></span></a>
// </li>
// </ul> */}
//     </div>
//     <div className="product__item__text">
//         <h6><a href="#">Slim striped pocket shirt</a></h6>

//         <div className="product__price">₹ 59.0</div>
//     </div>
// </div>
// </div> */}



const AllProducts = () => {
    const product1 = require("../img/product/product-1.jpg").default
    const product2 = require("../img/product/product-2.jpg").default
    const product3 = require("../img/product/product-3.jpg").default
    const product4 = require("../img/product/product-4.jpg").default
    const product5 = require("../img/product/product-5.jpg").default
    const product6 = require("../img/product/product-6.jpg").default
    const product7 = require("../img/product/product-7.jpg").default
    const product8 = require("../img/product/product-8.jpg").default

    const [products, setProducts] = useState([]);

    const getAllProducts = async () => {
        const productArray = []
        const querySnapshot = await getDocs(collection(db, "product"));
        console.log("querySnapshot", querySnapshot)
        querySnapshot.forEach(async (docc) => {
            const data = docc.data()
            data.product.id = docc.id;

            console.log("data", data)
            productArray.push(data.product)
        })
        setProducts(productArray.sort((a, b) => a.id > b.id ? 1 : 0));
    }

    useEffect(() => {
        getAllProducts()
    }, [])

    console.log({ products })

    return (
        <><div className="row">
            <div className="col-lg-12 text-center">

            </div>

            {
                products.map((p) => {
                    return (
                        <Link to={`/productdetails/${p.id}?category=${p.Categories[0]}`} className="col-lg-3 col-md-4 col-sm-6">
                            <div className="product__item">
                                <div
                                    className="product__item__pic set-bg"
                                    style={{
                                        backgroundImage: `url(${p.URLS[0]})`,
                                    }}
                                >
                                    {/* <ul className="product__hover">
        <li>
        <a href="img/product/related/rp-4.jpg" className="image-popup"
        ><span className="arrow_expand"></span
        ></a>
        </li>
        <li>
        <a href="#"><span className="icon_heart_alt"></span></a>
        </li>
        <li>
        <a href="#"><span className="icon_bag_alt"></span></a>
        </li>
        </ul> */}
                                </div>
                                <div className="product__item__text">
                                    <h6><a href="#">{p.Name}</a></h6>

                                    {/* <div className="product__price">₹ 100</div> */}
                                </div>
                            </div>
                        </Link>
                    )
                })
            }

        </div></>
    )
}

export default AllProducts
