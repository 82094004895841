import React, {useState} from 'react'
import styled from 'styled-components'
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { useHistory } from "react-router-dom";

const Login = () => {
    const logo = require("../../src/img/crown.png").default
// const [email,setEmail] = useState('aabid@gmail.com')
// const [password,setPassword] = useState('123456789')
const [email,setEmail] = useState('')
const [password,setPassword] = useState('')
let history = useHistory();

    const auth = getAuth();
    const signIn = (e)=>{
      e.preventDefault();
    console.log("auth=>",auth,"email=>",email,"password=>",password)

      signInWithEmailAndPassword(auth, email, password)
      .then(async(userCredential) => {
        // Signed in 
        console.log('userCreadential=>',userCredential)
        const user = userCredential.user;
        console.log('user logged in=>',user.accessToken)
       await localStorage.setItem('user', user.accessToken);
        history.push('/admin')
        // ...
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        alert(error)
        console.log('errorCode=>',errorCode,'errorMessage=>',errorMessage)
        // ..
      });
    }
   
    return (
        <Wrapper>
  {console.log('login page ')}
            <section className="text-center container" style={{ backgroundColor: "#fff",height:"100vh" }}>
                <form className="form-signin footer-form" style={{flexDirection:"column",alignItems:"center"}}>
                    <img className="mb-4" src={logo} alt="" width="72" height="72" />
                    <h1 className="h3 mb-3 font-weight-normal">Please sign in</h1>
                    <label for="inputEmail" className="sr-only">Email address</label>
                    <input type="email" id="inputEmail" className="form-control" value={email} onChange={e => setEmail(e.target.value)} placeholder="Email address" required autofocus />
                    <label for="inputPassword" className="sr-only">Password</label>
                    <input type="password" id="inputPassword" className="form-control" value={password} onChange={e => setPassword(e.target.value)}  placeholder="Password" required />

                    <button className="btn btn-dark btn-primary btn-block" onClick={signIn} type="submit">Sign in</button>
                    <p className="mt-5 mb-3 text-muted">Casco</p>
                </form>
            </section>
        </Wrapper>
    )
}

const Wrapper = styled.section`
 
.container {
  height: 100%;
}

.container {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-align: center;
  -ms-flex-pack: center;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: #f5f5f5;
}

.form-signin {
  width: 100%;
  max-width: 330px;
  padding: 15px;
  margin: 0 auto;
}
.form-signin .checkbox {
  font-weight: 400;
}
.form-signin .form-control {
  position: relative;
  box-sizing: border-box;
  height: auto;
  padding: 10px;
  font-size: 16px;
}
.form-signin .form-control:focus {
  z-index: 2;
}
.form-signin input[type="email"] {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.form-signin input[type="password"] {
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}`

export default Login
