// Initial State of Context API

export const initialState = {
    test: '',
    productArray: [],
    imageArrayContext: []

}

// Reducer of Context API

const reducer = (state, action) => {
    switch (action.type) {
        case 'test':
            return {
                ...state,
                test: action.test
            }
        case 'productArrayCase':
            return {
                ...state,
                productArrayReturn: action.productArray
            }
        case 'imageArrayCase':
            return {
                ...state,
                imageArrayContextReturn: action.imageArrayContext
            }
        default:
            return state
    }
}

export default reducer