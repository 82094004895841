import React, { useEffect, useState } from 'react'
import { Carousel } from 'react-bootstrap';
import Footer from '../Components/Footer'
import Header from '../Components/Header'
import {
    BrowserRouter as Router,
    Switch,
    useLocation,
    useParams,
    Link
} from "react-router-dom";

import db from "../firebase"
import { getStorage, ref, uploadBytesResumable, getDownloadURL, getMetadata } from "firebase/storage";
import { collection, onSnapshot, addDoc, getDocs, getDoc, doc, updateDoc, arrayUnion } from 'firebase/firestore';

const ProductDetailsPage = () => {

    const productId = useParams();
    const history = useLocation()
    let productCategory = history.search.split("?category=")?.[1]

    const [productDetail, setproductDetail] = useState({
        "URLS": [
        ],
        "Categories": [],
        "Name": "",
        "Description": "",
        "variant": [

        ]
    })

    const getProductInfo = async () => {
        const data = await getDoc(doc(db, "product", productId.id));
        if(!data.data()) return alert("Product Not Found");
        setproductDetail(data.data()?.product);
    }


    useEffect(() => {
        getProductInfo()
    }, [])

    const categoryPageRedirect = (name)=>{
        switch (name) {
            case "LunchBox":
                return "cookware";
            case "Veg-Graters":
                return "veggraters";
            case "Kitchen Tools":
                return "kitchentools";
            case "Cutlery":
                return "cutlery";
        }
    }

    const HomeIcon = ()=>     <svg style={{"fontSize":"1rem","height":"14px","width":"16px","marginRight":"0.5rem"}} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M575.8 255.5C575.8 273.5 560.8 287.6 543.8 287.6H511.8L512.5 447.7C512.5 450.5 512.3 453.1 512 455.8V472C512 494.1 494.1 512 472 512H456C454.9 512 453.8 511.1 452.7 511.9C451.3 511.1 449.9 512 448.5 512H392C369.9 512 352 494.1 352 472V384C352 366.3 337.7 352 320 352H256C238.3 352 224 366.3 224 384V472C224 494.1 206.1 512 184 512H128.1C126.6 512 125.1 511.9 123.6 511.8C122.4 511.9 121.2 512 120 512H104C81.91 512 64 494.1 64 472V360C64 359.1 64.03 358.1 64.09 357.2V287.6H32.05C14.02 287.6 0 273.5 0 255.5C0 246.5 3.004 238.5 10.01 231.5L266.4 8.016C273.4 1.002 281.4 0 288.4 0C295.4 0 303.4 2.004 309.5 7.014L564.8 231.5C572.8 238.5 576.9 246.5 575.8 255.5L575.8 255.5z"/></svg>

    console.log('aaaaaaaaaaaa',categoryPageRedirect(productCategory));
    return (
        <>
            <Header />
            <div className="breadcrumb-option">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div class="breadcrumb__links" style={{ display:"flex" }}>
                                <Link to={`/`} style={{ display:"flex",alignItems:"center" }}>
                                    {/* <i class="fas fa-home"></i> */}
                                    <HomeIcon />
                                    <span>Home</span>
                                  </Link>
                                {/* <a href="index.html"><i class="fas fa-home"></i> Home </a> */}
                                {/* <a href="#"><i class="fas fa-chevron-right"></i> Women’s </a> */}
                                <Link to={`/products/${categoryPageRedirect(productCategory)}`}><i class="fas fa-chevron-right"></i>{productCategory}</Link>
                                <span><i class="fas fa-chevron-right" style={{ marginRight:"1rem" }}></i>{productDetail?.Name}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <section className="product-details spad">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="product__details__pic">
                                <div className="product__details__pic__left product__thumb nice-scroll">
                                    {
                                        productDetail?.URLS.map((item, key) => {
                                            return (
                                                <img
                                                    className="pt active"
                                                    src={item}
                                                    alt=""
                                                />
                                            )
                                        })
                                    }

                                </div>
                                <div className="product__details__slider__content">
                                    <Carousel interval={null}>
                                        {
                                            productDetail?.URLS.map((item, key) => {
                                                return (
                                                    <Carousel.Item>
                                                        <img
                                                            className="d-block w-100"
                                                            src={item}
                                                            alt="First slide"
                                                        />

                                                    </Carousel.Item>
                                                )
                                            })
                                        }

                                    </Carousel>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="product__details__text">
                                <h3>
                                    {productDetail?.Name}
                                    <span>Brand: {productDetail?.Description}</span>
                                </h3>

                            </div>

                            <div className="sizetable">

                                <h6 style={{ fontWeight:"bolder" }}>Available Sizes</h6>
                                <table class="table table-bordered" style={{ borderStyle:"solid",borderColor:"#D0C9C0",borderWidth:"1px" }}>

                                    <thead>
                                        <tr>
                                            <th scope="col" style={{ color:"#888",borderColor:"#D0C9C0",borderWidth:"1px" }}>Size</th>
                                            <th scope="col" style={{ color:"#888",borderColor:"#D0C9C0",borderWidth:"1px" }}>Dimension</th>
                                            <th scope="col" style={{ color:"#888",borderColor:"#D0C9C0",borderWidth:"1px" }}>Master Qty</th>
                                            <th scope="col" style={{ color:"#888",borderColor:"#D0C9C0",borderWidth:"1px" }}>Master Dimension</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {productDetail?.variant.map((item, key) => {
                                            return (<tr>
                                                <th scope="row">{item.size}</th>
                                                <td>{item.dim}</td>
                                                <td>{item.mstQty}</td>
                                                <td>{item.mstDim}</td>
                                            </tr>)
                                        })}
                                    </tbody>

                                </table>
                            </div>

                        </div>
                    </div>
                </div>
            </section>

            <Footer />
        </>
    )
}

export default ProductDetailsPage
