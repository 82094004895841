import React, { useEffect, useState } from 'react'
import db from "../firebase"
import { getStorage, ref, uploadBytesResumable, getDownloadURL, getMetadata } from "firebase/storage";
import { collection, onSnapshot, addDoc, getDocs, getDoc, doc, updateDoc, arrayUnion } from 'firebase/firestore';
import { useHistory, Link } from "react-router-dom";
import { useStateValue } from './StateProvider'

function CookingSpoons() {
    const [state, dispatch] = useStateValue();
    console.log("cookingspoons");
    const product1 = require("../img/product/product-1.jpg").default
    const product2 = require("../img/product/product-2.jpg").default
    const product3 = require("../img/product/product-3.jpg").default
    const product4 = require("../img/product/product-4.jpg").default
    const product5 = require("../img/product/product-5.jpg").default
    const product6 = require("../img/product/product-6.jpg").default
    const product7 = require("../img/product/product-7.jpg").default
    const product8 = require("../img/product/product-8.jpg").default
    // const [kitchenTools, setKitchenTools] = useState([])
    // let history = useHistory();
    // useEffect(() => {

    //     getProduct();
    // }, [])
    // const getProduct = async () => {
    //     const querySnapshot = await getDocs(collection(db, "category"));
    //     querySnapshot.forEach(async (docc) => {
    //         // console.log(docc.id, '=>', docc.data());
    //         const data = docc.data()
    //         if (data.category === "Kitchen Tools") {
    //             data.product.map(async (item, key) => {
    //                 // console.log('item=>', item)
    //                 const washingtonRef = doc(db, "product", item);
    //                 console.log('washingtonRef=>', washingtonRef)

    //                 const refDoc = await getDoc(washingtonRef)

    //                 console.log('refDoc=>', refDoc.data())
    //                 const productP = refDoc.data()
    //                 console.log('productArray=>', productP?.product)
    //                 productArray.push(productP.product)
    //                 console.log('productArray=>', productArray)
    //                 dispatch({
    //                     type: 'productArrayCase',
    //                     productArray: productArray
    //                 })
    //                 console.log('state.productArray=> out',productArray.length , productArray)

    //             })
    //             console.log('productArray=>',productArray,'out')
    //                 setKitchenTools(productArray)
    //             // setKitchenTools(prevKitchenTools => ([...prevKitchenTools, ...productArray]));

    //         }

    //     });



    // }


    const [products, setProducts] = useState([]);
    const getAllProducts = async () => {
        const productArray = []
        const querySnapshot = await getDocs(collection(db, "product"));
        console.log("querySnapshot", querySnapshot)
        querySnapshot.forEach(async (docc) => {
            const data = docc.data()
            if (!data.product.Categories.includes("Veg-Graters")) return;

            data.product.id = docc.id;
            console.log("data", data)
            productArray.push(data.product)
        })
        setProducts(productArray.sort((a, b) => a.id > b.id ? 1 : 0));
    }

    useEffect(() => {
        getAllProducts()
    }, [])



    return (
        <div className="row">
            <div className="col-lg-12 text-center">
                {/* {console.log("productArray=>", productArray?.length, productArray)}
                {console.log('state.productArray=>', state.productArrayReturn)} */}
            </div>
            {
                products.map((p, key) => {
                    return (<Link to={`/productdetails/${p.id}?category=Veg-Graters`} className="col-lg-3 col-md-4 col-sm-6" >
                        <div className="product__item">
                            <div
                                className="product__item__pic set-bg"
                                style={{
                                    backgroundImage: `url(${p.URLS[0]})`,
                                }}
                            >
                                {/* <div className="label new">{key}</div> */}
                                {/* <ul className="product__hover">
                                    <li>
                                        <a href="img/product/related/rp-1.jpg" className="image-popup"
                                        ><span className="arrow_expand"></span
                                        ></a>
                                    </li>
                                    <li>
                                        <a href="#"><span className="icon_heart_alt"></span></a>
                                    </li>
                                    <li>
                                        <a href="#"><span className="icon_bag_alt"></span></a>
                                    </li>
                                </ul> */}
                            </div>
                            <div className="product__item__text">
                                <h6><a href="#">{p.Name}</a></h6>

                                {/* <div className="product__price">₹ 58.0*</div> */}
                            </div>
                        </div>
                    </Link>)
                })
            }

        </div>
    )
}

export default CookingSpoons
