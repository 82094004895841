// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore"
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// const firebaseConfig = {
//     apiKey: "AIzaSyCJ-JLDfxkz-ymU2BM0KSZvkAE4ESmV9BY",
//     authDomain: "casco-ec1dd.firebaseapp.com",
//     databaseURL: "https://casco-ec1dd-default-rtdb.firebaseio.com",
//     projectId: "casco-ec1dd",
//     storageBucket: "casco-ec1dd.appspot.com",
//     messagingSenderId: "21976278220",
//     appId: "1:21976278220:web:68804549e84467a6300e69"
// };

const firebaseConfig = {
    apiKey: "AIzaSyBZrR1Eif9HUJ8fpL7WFQYc9i01nxBkVmg",
    authDomain: "casco-website.firebaseapp.com",
    projectId: "casco-website",
    storageBucket: "casco-website.appspot.com",
    messagingSenderId: "649149815117",
    appId: "1:649149815117:web:c001192d9442bb081b3f04",
    measurementId: "G-GXTM8813N4"
  };

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore();
export default db
