import React from 'react';
import ReactDOM from 'react-dom';
import App from "./App"
import { AppProvider } from './context';
import "./css/bootstrap.min.css"
import "./index.css"

import {StateProvider} from "./Components/StateProvider";
import reducer, { initialState } from "./Components/reducer";

ReactDOM.render(
  <React.StrictMode>
    <StateProvider initialState={initialState} reducer={reducer}>

    <AppProvider>
      <App />
    </AppProvider>
    </StateProvider>

  </React.StrictMode>,
  document.getElementById('root')
);