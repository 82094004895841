import React, { useEffect, useState } from 'react'
import db from "../firebase"
import { getStorage, ref, uploadBytesResumable, getDownloadURL, getMetadata } from "firebase/storage";
import { collection, onSnapshot, addDoc, getDocs, getDoc, doc, updateDoc, arrayUnion } from 'firebase/firestore';
import { useHistory,Link } from "react-router-dom";
import { useStateValue } from './StateProvider'

const VegSpoons = () => {
    const [state, dispatch] = useStateValue();

    const product1 = require("../img/product/product-1.jpg").default
    const product2 = require("../img/product/product-2.jpg").default
    const product3 = require("../img/product/product-3.jpg").default
    const product4 = require("../img/product/product-4.jpg").default
    const product5 = require("../img/product/product-5.jpg").default
    const product6 = require("../img/product/product-6.jpg").default
    const product7 = require("../img/product/product-7.jpg").default
    const product8 = require("../img/product/product-8.jpg").default
    const [vegSpoons, setVegSpoons] = useState([])
    let history = useHistory();

    // useEffect(() => {
    //     getProduct();
    // }, [])
    // const getProduct = async () => {
    //     const productArray = []
    //     const querySnapshot = await getDocs(collection(db, "category"));
    //     console.log("querySnapshot",querySnapshot)
    //     querySnapshot.forEach(async (docc) => {
    //         const data = docc.data()
    //         console.log("data",data)

    //         // if (data.category === "Veg-Graters") {
    //             // data.product.map(async (item, key) => {
    //             //     const washingtonRef = doc(db, "product", item);
    //             //     const refDoc = await getDoc(washingtonRef)
    //             //     console.log('refDoc=>', refDoc.data())
    //         //         const productP = refDoc.data()
    //         //         console.log('productP.product=>', productP?.product)
    //         //         productArray.push(productP?.product)
    //         //         console.log('productArray=>', productArray)
                    
    //         //         dispatch({
    //         //             type: 'productArrayCase',
    //         //             productArray: productArray
    //         //         })
    //         //         setVegSpoons(productArray)
    //             // })
    //         // }
    //     });

    // }

    const [products, setProducts] = useState([]);
    const getAllProducts = async () => {
        const productArray = []
        const querySnapshot = await getDocs(collection(db, "product"));
        console.log("querySnapshot", querySnapshot)
        querySnapshot.forEach(async (docc) => {
            const data = docc.data()
            if (!data.product.Categories.includes("Kitchen Tools")) return;

            data.product.id = docc.id;
            console.log("data", data)
            productArray.push(data.product)
        })
        setProducts(productArray.sort((a, b) => a.id > b.id ? 1 : 0));
    }

    useEffect(() => {
        getAllProducts()
    }, [])



    return (
        <div className="row">
            <div className="col-lg-12 text-center">
                {console.log("vegSpoons=>", vegSpoons)}
            </div>
            {
                products.map((p, key) => {
                    return (<Link to={`/productdetails/${p.id}?category=Kitchen Tools`} className="col-lg-3 col-md-4 col-sm-6" >
                        <div className="product__item">
                            <div
                                className="product__item__pic set-bg"
                                style={{
                                    backgroundImage: `url(${p.URLS[0]})`,
                                }}
                            >
                                {/* <div className="label new">{key}</div> */}
                                {/* <ul className="product__hover">
                                    <li>
                                        <a href="img/product/related/rp-1.jpg" className="image-popup"
                                        ><span className="arrow_expand"></span
                                        ></a>
                                    </li>
                                    <li>
                                        <a href="#"><span className="icon_heart_alt"></span></a>
                                    </li>
                                    <li>
                                        <a href="#"><span className="icon_bag_alt"></span></a>
                                    </li>
                                </ul> */}
                            </div>
                            <div className="product__item__text">
                                <h6><a href="#">{p.Name}</a></h6>

                                {/* <div className="product__price">₹ 59.0*</div> */}
                            </div>
                        </div>
                    </Link>)
                })
            }
        </div>
    )
}

export default VegSpoons
