
import React, { useEffect, useState } from 'react'
import { Alert, Col, Form, Row } from 'react-bootstrap'
import Footer from '../Components/Footer'
import Header from '../Components/Header'
import Instagram from '../Components/Instagram'
import db from "../firebase"
import { collection, onSnapshot, addDoc, getDocs, getDoc, doc, updateDoc, arrayUnion } from 'firebase/firestore';

import { ToastContainer, toast } from 'react-toastify';


const Contact = () => {

    const [firstName, setfirstName] = useState("");
    const [lastName, setlastName] = useState("");
    const [company, setcompany] = useState("");
    const [email, setemail] = useState("");
    const [phone, setphone] = useState("");
    const [subject, setsubject] = useState("");
    const [message, setmessage] = useState("");

    // const validateEmail = (email) => {
    //     return String(email)
    //       .toLowerCase()
    //       .match(
    //         /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    //       );
    //   };

    const submitContactData = async () => {
        // Gather form data
        const formData = { firstName, lastName, company, email, phone, subject, message };

        // Validate required fields
        const requiredFields = ['firstName', 'lastName', 'company', 'email', 'phone', 'subject', 'message'];
        const missingFields = requiredFields.filter(field => !formData[field]);

        // Display error if any required field is missing
        if (missingFields.length > 0) {
            return toast.error("Please Fill All Required Fields");
        }

        // Validate email
        if (!validateEmail(email)) {
            return toast.error("Please enter a valid email.");
        }

        // Validate name fields (no numbers allowed)
        if (!isValidName(firstName) || !isValidName(lastName)) {
            return toast.error("Name fields cannot contain numbers.");
        }

        // Validate phone field (only numbers allowed)
        if (!isValidPhone(phone)) {
            return toast.error("Phone number can only contain numbers.");
        }

        try {
            // Add form data to Firestore
            const docRef = await addDoc(collection(db, "contact-forms"), formData);
            console.log("Document written with ID: ", docRef.id);
            toast.success("Form successfully submitted");

            // Clear form fields after successful submission
            clearFormFields();
        } catch (error) {
            console.error("Error writing document: ", error);
            toast.error("Error submitting form. Please try again later.");
        }
    }

    // Function to validate email format
    const validateEmail = (email) => {
        // Regular expression for email validation
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    }

    // Function to validate name (no numbers allowed)
    const isValidName = (name) => {
        // Regular expression for name validation
        const nameRegex = /^[^\d]+$/;
        return nameRegex.test(name);
    }

    // Function to validate phone (only numbers allowed)
    const isValidPhone = (phone) => {
        // Regular expression for phone number validation
        const phoneRegex = /^\d+$/;
        return phoneRegex.test(phone);
    }

    // Function to clear form fields
    const clearFormFields = () => {
        setfirstName("");
        setlastName("");
        setcompany("");
        setemail("");
        setphone("");
        setsubject("");
        setmessage("");
    }


    // create category for first time 


    return <>
        <Header />

        {/* ------------------------------------------------------------------------------------------------- */}

        <section className="contact spad">
            <div className="container">
                <ToastContainer position="top-center" />

                <div className="row">
                    <div className="col-lg-6 col-md-6 contact-info-row">
                        <div className="contact__content">
                            <div className="contact__address">
                                <h3 className="contact-line">Contact Us</h3>
                                <br />
                                <br />

                                <p>We are looking for ambitious & smart people to partner with us.  If you are a distributor, modern trade retailer or even someone who wants to start a new business in the consumer durable space, we are interested in speaking with you. <br /> <br /> Partner with a fast growing consumer durable brand that actually cares for its end consumers. Partner with us.</p>
                                {/* <p>It all begins with an idea. Maybe you want to launch a business. Maybe you want to turn a hobby into something more. Or maybe you have a creative project to share with the world. Whatever it is, the way you tell your story online can make all the difference</p> */}
                                {/* <p>Clothing Courtesy of Lauren Winter, Kinem and Ulihu</p> */}
                                <br />
                                <br />
                                <br />
                                <br />
                                <h3 className="contact-line">Feel free to Visit us</h3>
                                <br />
                                <br />
                                <p>Unit no.9-10, Bldg No. 5 <br />
                                    Jay Chamundeshwari Ind. Complex <br />
                                    Near Ganesh Mandir, Naikpada <br />
                                    Waliv, Vasai East - 401208</p>
                                <br />
                                <br />
                                <p>Karan Soi    : +91 91724 30741 <br />
                                    Sanjay Soi   : +91 93232 79197</p>
                            </div>

                        </div>
                    </div>
                    <div className="col-lg-5 col-md-5">
                        <div className="contact__map">
                            <Form>
                                <Row className="mb-3">
                                    <Form.Group as={Col} controlId="formGridEmail">
                                        <Form.Label>First Name</Form.Label>
                                        <Form.Control type="text" onChange={(e) => setfirstName(e.target.value)} value={firstName} />
                                    </Form.Group>

                                    <Form.Group as={Col} controlId="formGridPassword">
                                        <Form.Label>Last Name</Form.Label>
                                        <Form.Control type="text" onChange={(e) => setlastName(e.target.value)} value={lastName} />
                                    </Form.Group>
                                </Row>

                                <Form.Group className="mb-3" controlId="formGridAddress1">
                                    <Form.Label>Company Name</Form.Label>
                                    <Form.Control onChange={(e) => setcompany(e.target.value)} value={company} />
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="formGridEmail">
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control type="email" onChange={(e) => setemail(e.target.value)} value={email} />
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="formGridPhone">
                                    <Form.Label>Phone</Form.Label>
                                    <Form.Control onChange={(e) => setphone(e.target.value)} value={phone} />
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="formGridPhone">
                                    <Form.Label>Subject</Form.Label>
                                    <Form.Control onChange={(e) => setsubject(e.target.value)} value={subject} />
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="formGridMessage">
                                    <Form.Label>Message</Form.Label>
                                    <Form.Control as="textarea" rows={3} onChange={(e) => setmessage(e.target.value)} value={message} />
                                </Form.Group>




                                <button type="button" className="btn btn-dark button1" onClick={submitContactData}>Submit</button>


                            </Form>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        {/* ------------------------------------------------------------------------------------------------- */}

        <Instagram />

        {/* ------------------------------------------------------------------------------------------------- */}

        <Footer />
    </>
}

export default Contact
