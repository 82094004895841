/* eslint-disable no-whitespace-before-property */
/* eslint-disable default-case */

import React, { useState, useEffect } from 'react'
import { Container, Form, Button, Row, Col } from 'react-bootstrap'
import { useGlobalContext } from '../context'
import db from "../firebase"
import { getStorage, ref, uploadBytesResumable, getDownloadURL, getMetadata } from "firebase/storage";
import { collection, onSnapshot, addDoc, getDocs, getDoc, doc, updateDoc, arrayUnion } from 'firebase/firestore';
import DataTable from 'react-data-table-component';
import * as XLSX from 'xlsx';
import { getAuth } from "firebase/auth";
import { useHistory } from "react-router-dom";
import { useStateValue } from '../Components/StateProvider'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

let imageArrayContext = []
const Admin = () => {

    const storage = getStorage();
    const [state, dispatch] = useStateValue();

    const { contactforms, newsletters, exportToCSV } = useGlobalContext()
    console.log("export btn", contactforms, newsletters);
    const [images, setImages] = useState([]);
    const [imageURl, setImageURL] = useState([])
    const [columns, setColumns] = useState([]);
    const [data, setData] = useState([]);
    const [user, setUser] = useState(null)
    const [submitted, setSubmitted] = useState(false)
    const [imageSubmitted, setImageSubmitted] = useState(false)
    let history = useHistory();


    // process CSV data
    const processData = async (dataString) => {
        setSubmitted(false)
        const dataStringLines = dataString.split(/\r\n|\n/);
        const headers = dataStringLines[0].split(/,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/);

        const list = [];
        for (let i = 1; i < dataStringLines.length; i++) {
            const row = dataStringLines[i].split(/,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/);
            if (headers && row.length == headers.length) {
                const obj = {};
                for (let j = 0; j < headers.length; j++) {
                    let d = row[j];
                    if (d.length > 0) {
                        if (d[0] == '"')
                            d = d.substring(1, d.length - 1);
                        if (d[d.length - 1] == '"')
                            d = d.substring(d.length - 2, 1);
                    }
                    if (headers[j]) {
                        obj[headers[j]] = d;
                    }
                }

                // remove the blank rows
                if (Object.values(obj).filter(x => x).length > 0) {
                    list.push(obj);
                }
            }
        }

        // prepare columns list from headers
        const columns = headers.map(c => ({
            name: c,
            selector: c,
        }));

        setData(list);
        console.log("columns=>", columns, columns.length)
        setColumns(columns);
    }

    // upload file xlsx to firebase 
    const uploadToFirebase = async (e) => {
        e.preventDefault();
        if (data) {
            console.log('list of data=>', data);
            let dataToUpload = [];

            data.forEach((item, key) => {
                let Categories = item.Categories.split(', ');
                let Description = item.Description;
                let Name = item.Name;

                let variant = [];
                for (let i = 1; i <= 10; i++) {
                    if (item[`variant${i}`] !== undefined && item[`variant${i}`] !== "") {
                        let variantDetails = item[`variant${i}`].split(', ');
                        if (variantDetails.length > 0) {
                            variant.push({
                                size: variantDetails[0] || "",
                                dim: variantDetails[1] || "",
                                mstQty: variantDetails[2] || "",
                                mstDim: variantDetails[3] || ""
                            });
                        }
                    }
                }

                let URLS = item.URLS.split(', ');

                let dataToPutInArray = {
                    "Categories": Categories,
                    "Description": Description,
                    "Name": Name,
                    "variant": variant,
                    "URLS": URLS
                };

                dataToUpload.push(dataToPutInArray);
            });

            for (const product of dataToUpload) {
                try {
                    const docRef = await addDoc(collection(db, "product"), { product });
                    const querySnapshot = await getDocs(collection(db, "category"));
                    for (const docc of querySnapshot.docs) {
                        const data = docc.data();
                        if (product.Categories.includes(data.category)) {
                            await updateDoc(doc(db, "category", docc.id), {
                                product: arrayUnion(docRef.id)
                            });
                        }
                    }
                } catch (error) {
                    console.error("Error adding document: ", error);
                }
            }

            setSubmitted(true);
            toast.success("Data uploaded successfully!");
        }
    };


    // route to login page 


    useEffect(() => {
        (async () => {
            let getUser = await localStorage.getItem('user')
            setUser(getUser)
            console.log('getUser=>', getUser)
            console.log('user=>', user)
            const auth = getAuth()
            const users = auth.currentUser
            console.log('users=>', users)

            if (getUser !== null) {
                // The user object has basic properties such as display name, email, etc.
                //   const displayName = user.displayName;
                //   const email = user.email;
                //   const photoURL = user.photoURL;
                //   const emailVerified = user.emailVerified;
                console.log('user exist')

                // The user's ID, unique to the Firebase project. Do NOT use
                // this value to authenticate with your backend server, if
                // you have one. Use User.getToken() instead.
                //   const uid = user.uid;
            }
            else {
                console.log('user=>', user)
                history.push('/login')

            }
        })()


    }, [])
    // handle file upload
    const handleFileUpload = e => {
        const file = e.target.files[0];
        console.log("file csv=>", file)

        const reader = new FileReader();
        reader.onload = (evt) => {
            /* Parse data */
            const bstr = evt.target.result;
            const wb = XLSX.read(bstr, { type: 'binary' });
            /* Get first worksheet */
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            /* Convert array of arrays */
            const data = XLSX.utils.sheet_to_csv(ws, { header: 1 });
            processData(data);
            console.log("data csv=>", data)
        };
        reader.readAsBinaryString(file);
    }
    const demo2 = () => {

        const data = [{ "category": "Kitchen Tools", "URL": "https://firebasestorage.googleapis.com/v0/b/casco-ec1dd.appspot.com/o/images%2FKitchen%20tools.jpg?alt=media" },
        { "category": "Cutlery", "URL": "https://firebasestorage.googleapis.com/v0/b/casco-ec1dd.appspot.com/o/images%2FTM%20Cutlery%20Banner%20New.jpg?alt=media" },
        { "category": "LunchBox", "URL": "https://firebasestorage.googleapis.com/v0/b/casco-ec1dd.appspot.com/o/images%2Fcookware.png?alt=media" },
        { "category": "Veg-Graters", "URL": "https://firebasestorage.googleapis.com/v0/b/casco-ec1dd.appspot.com/o/images%2FBXS_0077.JPG?alt=media" }]
        console.log('data', data)
        let product = []
        data.map(async (item, key) => {
            console.log('category=>', item, key)
            const docRef = await addDoc(collection(db, "category"), {
                "category": item.category, "product": product, "URL": item.URL
            });
            console.log("docRef=>", docRef)
        })

    }




    const getProduct = async (name) => {
        const productArray = []
        const querySnapshot = await getDocs(collection(db, "category"));
        querySnapshot.forEach(async (docc) => {
            console.log(docc.id, '=>', docc.data());
            const data = docc.data()
            if (data.category === name) {
                data.product.map(async (item, key) => {
                    console.log('item=>', item)
                    const washingtonRef = doc(db, "product", item);
                    console.log('washingtonRef=>', washingtonRef)

                    const refDoc = await getDoc(washingtonRef)

                    console.log('refDoc=>', refDoc.data())
                    const productP = refDoc.data()
                    console.log('productP.product.product=>', productP.product)
                    productArray.push(productP.product)
                    console.log('productArray=>', productArray)

                })
            }
        });

    }
    const handleChange = (e) => {
        setImageURL([])
        setImages([])
        for (let i = 0; i < e.target.files.length; i++) {
            const newImage = e.target.files[i];
            newImage["id"] = Math.random();
            setImages((prevState) => [...prevState, newImage])

        }
        // setImages(e.target.files[0])
    }

    // Create the file metadata
    /** @type {any} */
    const metadata = {
        contentType: 'image/png' || "image/jpg" || 'image/jpeg'
    };

    // Upload file and metadata to the object 'images/mountains.jpg'


    // Listen for state changes, errors, and completion of the upload.


    const handleUpload = (e) => {
        let imageArray = []
        e.preventDefault();

        images.map((image) => {
            const storageRef = ref(storage, 'images/' + image.name);
            const uploadTask = uploadBytesResumable(storageRef, image, metadata);
            uploadTask.on('state_changed',
                (snapshot) => {
                    // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                    const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    console.log('Upload is ' + progress + '% done');
                    switch (snapshot.state) {
                        case 'paused':
                            console.log('Upload is paused');
                            break;
                        case 'running':
                            console.log('Upload is running');
                            break;
                    }
                },
                (error) => {
                    // A full list of error codes is available at
                    // https://firebase.google.com/docs/storage/web/handle-errors
                    switch (error.code) {
                        case 'storage/unauthorized':
                            // User doesn't have permission to access the object
                            break;
                        case 'storage/canceled':
                            // User canceled the upload
                            break;

                        // ...

                        case 'storage/unknown':
                            // Unknown error occurred, inspect error.serverResponse
                            break;
                    }
                },
                () => {
                    // Upload completed successfully, now we can get the download URL
                    getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                        console.log('File available at', downloadURL);
                        setImageSubmitted(true)
                        imageArrayContext.push(downloadURL)
                        dispatch({
                            type: 'imageArrayCase',
                            imageArrayContext: imageArrayContext
                        })
                        // imageArray.push(downloadURL)

                        // setImageURL(imageArray)
                    });
                }
            );

        })
    }
    const logout = async () => {
        console.log('logout')
        await localStorage.clear();
        history.push('/login')


    }



    return (
        <>
            <Container className="admin-container" style={{ width: "60%" }}>
                <div style={{ width: '100%', height: 40, padding: 10 }} className="admin-header">
                    <h1 className="admin-title">casco Admin Panel</h1>
                    <p className="admin-logout-btn" onClick={logout}>Log Out</p>
                </div>


                <Form>

                    <Form.Group controlId="formFileMultiple" className="mb-3">
                        <Form.Label>Multiple Files Upload</Form.Label>
                        <Form.Control type="file" multiple onChange={handleChange} />
                        {
                            console.log("imageURl=>", imageURl)
                        }
                    </Form.Group>
                    {
                        imageSubmitted ? <Button variant="dark" type="submit" className="admin-normal-btn" >
                            Submitted
                        </Button> : <Button variant="dark" type="submit" className="admin-normal-btn" onClick={handleUpload}>
                            Submit
                        </Button>
                    }

                    {/* <div>
                        {imageURl ? imageURl.map(item =>{
                            return (<p>{item}</p>)
                        }) : null }
                    </div> */}
                    <div style={{ marginTop: 10 }}>
                        {state?.imageArrayContextReturn?.map((item, key) => {
                            return (<div style={{ width: '100%', justifyContent: 'center', alignItems: 'center', textAlign: 'center', display: 'flex', border: 'black solid 1px' }}>
                                <div style={{ width: '8%' }}>{key + 1}</div>
                                <div style={{ width: '15%' }}><img src={item} width={80} height={80} style={{ margin: 10, borderRadius: 5 }} /></div>
                                <div style={{ width: '57%', wordBreak: 'break-all' }}>{item}</div>
                                <div style={{ width: '20%', }}><Button variant="dark" onClick={() => { navigator.clipboard.writeText(item); toast("URL Copied!") }} >
                                    Copy URL
                                </Button></div>
                            </div>)
                        })}
                    </div>
                </Form>
                <br />
                <br />

                <Form style={{ marginBottom: 10 }}>
                    <Form.Group controlId="formFileLg" className="mb-3">
                        <Form.Label>Bulk Upload Products</Form.Label>
                        <Form.Control type="file" size="lg"
                            accept=".csv,.xlsx,.xls"
                            onChange={handleFileUpload}
                        />
                    </Form.Group>
                    {submitted ? <Button variant="dark" type="submit" className="admin-normal-btn">
                        Submitted
                    </Button> : <Button variant="dark" type="submit" className="admin-normal-btn" onClick={uploadToFirebase}>
                        Submit
                    </Button>}


                    {/* <button type="button" className="btn btn-dark button1" onClick={demo2}>category</button> */}
                    {/* <button type="button" className="btn btn-dark button1" onClick={demo1}>product</button> */}
                    {/* <button type="button" className="btn btn-dark button1" onClick={()=>getProduct('Cutlery')}>get Cutlery</button>
                    <button type="button" className="btn btn-dark button1" onClick={()=>getProduct('Kitchen Tools')}>get Kitchen Tools</button>
                    <button type="button" className="btn btn-dark button1" onClick={()=>getProduct('Veg-Graters')}>get Veg-Graters</button>
                    <button type="button" className="btn btn-dark button1" onClick={()=>getProduct('LunchBox')}>get LunchBox</button> */}

                </Form>
                <div>
                    {console.log("data=>", data)}
                    {console.log("columns=>", columns)}
                    <DataTable
                        pagination
                        highlightOnHover
                        columns={columns}
                        data={data}
                    />
                </div>
                <br />
                <h6>Export NewsLetters and Contact Forms:-</h6>
                <br />
                <div className="export-btn-container">
                    <Button variant="dark" type="submit" onClick={(e) => {
                        if (newsletters?.length > 0) {
                            exportToCSV(newsletters, "Newsletters")
                        } else {
                            toast.error("Newsletters is empty")
                        }
                    }}>
                        Export Newsletters
                    </Button>

                    <Button variant="dark" type="submit" onClick={(e) => {
                        if (contactforms?.length > 0) {
                            exportToCSV(contactforms, "Contact-Forms")
                        } else {
                            toast.error("contactforms is empty")
                        }
                    }}>
                        Export Contact Form
                    </Button>
                </div>
                <ToastContainer position="top-center" />
            </Container>


            <style jsx>
                {`

                    .admin-container{
                        padding-top:1.5rem;
                    }

                    .admin-logout-btn{
                        background-color: rgb(206, 212, 218);
                        border-radius: 20px;
                        padding: 10px;
                        width: 100px;
                        cursor: pointer;
                        float: right;
                        font-weight: bold;
                        text-align: center;
                    }

                    .admin-title{
                        font-weight:bold;
                            font-size: 30px;
                    }

                    .admin-container .admin-header{
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        margin-bottom: 2rem;
                    }

                    .admin-normal-btn{
                        padding: 10px 15px;
                        width: 140px;
                        font-size: 1.2rem;
                    }
                    .export-btn-container{
                        display:flex;
                        align-items:center;
                        justify-content:space-between;
                    }
                    .admin-container.container button {
                        border-radius: 0;
                        padding: 12px 15px;
                        font-size: 1rem;
                    }

                    @media screen and (max-width:1080px){
                        .admin-container{
                            width:100% !important;
                        }
                    }

                `}
            </style>
        </>
    )
}

export default Admin
