/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import VegGratersCom from '../Components/VegGraters'
import KitchenToolsComp from '../Components/KitchenTools'
import CutleryCom from '../Components/Cutlery'
import LunchBox from '../Components/Cookware'
import Footer from '../Components/Footer'
import Header from '../Components/Header'
import Instagram from '../Components/Instagram'
import AllProducts from '../Components/AllProducts'

import { useHistory, useParams } from 'react-router'
import { Link } from 'react-router-dom'

const ProductsPage = () => {
    const product1 = require("../../src/img/product/product-1.jpg").default
    const product2 = require("../../src/img/product/product-2.jpg").default
    const product3 = require("../../src/img/product/product-3.jpg").default
    const product4 = require("../../src/img/product/product-4.jpg").default
    const product5 = require("../../src/img/product/product-5.jpg").default
    const product6 = require("../../src/img/product/product-6.jpg").default
    const product7 = require("../../src/img/product/product-7.jpg").default
    const product8 = require("../../src/img/product/product-8.jpg").default

    const filterIcon = require("../../src/img/filter.png").default
    const closeIcon = require("../../src/img/close.png").default


    const [toggleProducts, settoggleProducts] = useState(true)
    const [kitchenTools, setKitchenTools] = useState(false)
    const [vegGraters, setVegGraters] = useState(false)
    const [Cutlery, setCutlery] = useState(false)
    const [cookware, setcookware] = useState(false)
    const [filterStatus, setfilterStatus] = useState(false)

    const history = useHistory();
    const category = useParams();
    useEffect(() => {
        switch (category.category) {
            case "lunchbox":
                handlecookwareClick()
                break;
            case "kitchentools":
                handleKitchenTools()
                break;
            case "veggraters":
                handleVegGraters()
                break;
            case "cutlery":
                handleCutlery()
                break;
            default:
                handleFilterAll();
        }
    }, [category])

    useEffect(()=>{
        window.scrollTo({ top: 0, behavior: 'smooth' });
    },[])

    const handleVegGraters = () => {
        setVegGraters(true);
        settoggleProducts(false);
        setKitchenTools(false);
        setCutlery(false);
        setcookware(false);
    }
    const handleProductsClick = () => {
        setVegGraters(false);
        settoggleProducts(true);
        setKitchenTools(false);
        setCutlery(false);
        setcookware(false);
    }
    const handleKitchenTools = () => {
        setKitchenTools(true);
        setVegGraters(false);
        settoggleProducts(false);
        setCutlery(false);
        setcookware(false);
    }
    const handleCutlery = () => {
        setVegGraters(false);
        settoggleProducts(false);
        setKitchenTools(false);
        setCutlery(true);
        setcookware(false);
    }
    const handlecookwareClick = () => {
        setVegGraters(false);
        settoggleProducts(false);
        setKitchenTools(false);
        setCutlery(false);
        setcookware(true);
    }
    const handleFilterAll = () => {
        settoggleProducts(true);
        setVegGraters(false);
        setKitchenTools(false);
        setCutlery(false);
        setcookware(false);
    }


    return (
        <>
            <Header />

            {/* ------------------------------------------------------------------------------------------------- */}

            <div className="container-fluid productsnew">

                <div className="navigator">
                    <div className="navigator-left">
                        {/* <h4 className={toggleProducts && "product-active brand-title"} onClick={handleProductsClick} style={{ cursor: "pointer" }}>Products</h4> */}
                        <h4 className={"product-active brand-title"} onClick={handleProductsClick} style={{ cursor: "pointer" }}>Products</h4>

                    </div>
                    <img src={filterIcon} height="30px" className="filter-btn" onClick={() => setfilterStatus(!filterStatus)} />

                    {filterStatus && <div className="filter-popup navigator">
                        <div className="filter-list navigator-right">
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                                <img src={closeIcon} className="filter-close-btn" onClick={() => setfilterStatus(!filterStatus)} height="25px" />
                            </div>
                            <Link to="/products/all"> <h6 className={toggleProducts && "product-active"} onClick={handleFilterAll} style={{ cursor: "pointer" }}>All</h6></Link>
                            <Link to="/products/lunchbox"> <h6 className={cookware && "product-active"} onClick={handlecookwareClick} style={{ cursor: "pointer" }}>LunchBox</h6> </Link>
                            <Link to="/products/kitchentools"><h6 className={kitchenTools && "product-active"} onClick={handleKitchenTools} style={{ cursor: "pointer" }}>Kitchen Tools</h6></Link>
                            <Link to="/products/veggraters"><h6 className={vegGraters && "product-active"} onClick={handleVegGraters} style={{ cursor: "pointer" }}>Veg-Graters</h6></Link>
                            <Link to="/products/cutlery"><h6 className={Cutlery && "product-active"} onClick={handleCutlery} style={{ cursor: "pointer" }}>Cutlery</h6></Link>
                        </div>
                    </div>}

                    <div className="navigator-right desktop">
                        <Link to="/products/all"> <h6 className={toggleProducts && "product-active"} onClick={handleFilterAll} style={{ cursor: "pointer" }}>All</h6></Link>
                        <Link to="/products/lunchbox"> <h6 className={cookware && "product-active"} onClick={handlecookwareClick} style={{ cursor: "pointer" }}>LunchBox</h6> </Link>
                        <Link to="/products/kitchentools"><h6 className={kitchenTools && "product-active"} onClick={handleKitchenTools} style={{ cursor: "pointer" }}>Kitchen Tools</h6></Link>
                        <Link to="/products/veggraters"><h6 className={vegGraters && "product-active"} onClick={handleVegGraters} style={{ cursor: "pointer" }}>Veg-Graters</h6></Link>
                        <Link to="/products/cutlery"><h6 className={Cutlery && "product-active"} onClick={handleCutlery} style={{ cursor: "pointer" }}>Cutlery</h6></Link>

                    </div>
                </div>

                {toggleProducts && <AllProducts />}
                {cookware && <LunchBox />}
                {kitchenTools && <KitchenToolsComp />}
                {vegGraters && <VegGratersCom />}
                {Cutlery && <CutleryCom />}


            </div>

            <style jsx>
                {`

                    .filter-popup{
                        position: absolute;
                        z-index: 111;
                        top: 0;
                        height: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 100%;
                        margin-left: -1rem;
                    }
                    .filter-popup .filter-list{
                        background: white;
                        padding: 10px;
                        min-width: 300px;
                        box-shadow: 5px 3px 10px black;
                        flex-direction: column;
                    }

                    .filter-list h6 {
                        font-size: 1.2rem;
                        margin-bottom: -2rem;
                    }

                    .filter-btn,.filter-close-btn{
                        cursor:pointer;
                    }
                
                `}
            </style>

            {/* ------------------------------------------------------------------------------------------------- */}

            <Instagram />

            {/* ------------------------------------------------------------------------------------------------- */}

            <Footer />

        </>
    )
}

export default ProductsPage
