import React, { useState, useEffect } from 'react'
import {
    Link
} from "react-router-dom";
import db from "../firebase"
import { collection, addDoc, getDocs } from 'firebase/firestore';
import { ToastContainer, toast } from 'react-toastify';

const Footer = () => {

    const [newsletter, setnewsletter] = useState('')
    const validateEmail = (email) => {
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
    };

    const postNewsletter = async () => {
        if (!newsletter) {
            return toast.error("Please Enter Email Address");
        } else if (!validateEmail(newsletter)) {
            return toast.error("Please enter a valid email.");
        }

        const docRef = await addDoc(collection(db, "newsletters"), {
            newsletter
        });
        if (docRef.id) toast.success("Email Address successfully submitted");

        console.log("Document written with ID: ", docRef.id);
        setnewsletter("")
    }
    return (<>
        <ToastContainer position="top-center" />

        <div className="maincontainer">

            <div className="maincontainer-left">
                <h6><Link style={{ color: "black" }} to="/products">Products</Link></h6>
                {/* <h6><Link style={{ color: "black" }} to="#">About Us</Link></h6> */}
                <h6><Link style={{ color: "black" }} to="/contact">Contact Us</Link></h6>

                <div className="d-none d-md-block">
                    <br />

                    <h6><Link style={{ color: "black" }} to="tel:+91 9172430741">Karan Soi : +91 9172430741</Link></h6>
                    <h6><Link style={{ color: "black" }} to="tel:+91 9323279197">Sanjay Soi : +91 9323279197</Link></h6>
                </div>
            </div>
            <div className="maincontainer-center">
                <p>Let's keep in touch</p>
                <form action="#" className="footer-form">
                    <input type="text" placeholder="Email Address" onChange={(e) => setnewsletter(e.target.value)} value={newsletter} />
                    <button type="button" className="btn btn-dark button1" onClick={postNewsletter}>Get in Touch</button>
                </form>


                <div className="d-block d-md-none">
                    <h6><Link style={{ color: "black" }} to="tel:+91 9172430741">Karan Soi : +91 9172430741</Link></h6>
                    <h6><Link style={{ color: "black" }} to="tel:+91 9323279197">Sanjay Soi : +91 9323279197</Link></h6>
                    <br />
                </div>


                <p>All Rights Rerserved by Sunshine Industries</p>
                <p>Designed & Developed by <a href="https://www.unicoglobal.in/" target="_blank">Unico Global</a></p>

            </div>
            <div className="maincontainer-right">
                <h6 ><Link to="/products/lunchbox" style={{ color: "black" }}>LunchBox</Link></h6>
                <h6 ><Link to="/products/kitchentools" style={{ color: "black" }}>Kitchen Tools</Link></h6>
                <h6 ><Link to="/products/veggraters" style={{ color: "black" }}>Veg Graters</Link></h6>
                <h6 ><Link to="/products/cutlery" style={{ color: "black" }}>Cutlery</Link></h6>
            </div>
        </div>

    </>
    )
}

export default Footer
