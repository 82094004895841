/* eslint-disable no-restricted-globals */
import React, { useState } from 'react'
import { Offcanvas, Table } from 'react-bootstrap';
import {
    Link
} from "react-router-dom";
import NavRow from './NavRow';

const Header = () => {
    // const logo = require("../../src/img/logo/Casco Logo.png").default
    const logo = require("../../src/img/crown.png").default
    // const middleLogo = require("../../src/img/logo/Casco Logo.png").default
    const middleLogo = require("../../src/img/logo.png").default


    const close = require("../../src/img/close.png").default

    const [show, setShow] = useState(false);
    const [showProducts, setShowProducts] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleCloseProducts = () => setShowProducts(false);
    const handleShowProducts = () => setShowProducts(true);

    return (<>
        <header className="header">
            <div className="container-fluid">
                <div className="row header-desk-con">
                    <div className="col-xl-3 col-lg-2">
                        <div className="header__logo">
                            <Link className="logo" to="/"><img src={logo} alt="Logo" style={{ height: "2.5rem" }} /></Link>
                        </div>
                    </div>
                    <div className="col-xl-6 col-lg-7">
                        <nav className="header__menu">
                            <ul>
                                {/* <h4 className="heading"><Link to="/" style={{ color: "black" }}>casco</Link></h4> */}
                                <Link className="logo" to="/"><img src={middleLogo} alt="Logo" style={{ height: "3.5rem" }} /></Link>
                            </ul>
                        </nav>
                    </div>
                    <div className="col-lg-3">
                        <div className="header__right">
                            <h6 className="products" onClick={handleShowProducts}>Products</h6>
                        </div>
                    </div>
                </div>
                <div className="canvas__open">
                    <i className="fas fa-bars" onClick={handleShow}></i>
                </div>
            </div>
        </header>
        {/* <Offcanvas show={show} onHide={handleClose} variant="dark">
            <Offcanvas.Header closeButton style={{ backgroundColor: "#fff" }}>
                <Offcanvas.Title>
                    <Link className="logo" to="/"><img src={logo} alt="Logo" style={{ height: "3rem" }} /></Link>
                </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body style={{ backgroundColor: "#212529" }}>
                <Table striped bordered hover variant="dark">

                    <tbody>
                        <tr>
                            <td onClick={handleClose}> <Link to="/" style={{ color: "#fff" }}>HOME</Link></td>
                        </tr>
                        <tr>
                            <td onClick={handleClose}><Link to="/products" style={{ color: "#fff" }}>PRODUCTS</Link></td>
                        </tr>
                        <tr>
                            <td onClick={handleClose}><Link to="/contact" style={{ color: "#fff" }}>CONTACT US</Link></td>
                        </tr>
                        <tr>
                            <td onClick={handleClose}><Link to="/productdetails" style={{ color: "#fff" }}>PRODUCT DETAILS</Link></td>
                        </tr>
                    </tbody>
                </Table>
            </Offcanvas.Body>
        </Offcanvas> */}
        <Offcanvas show={showProducts} onHide={handleCloseProducts} placement="top" name="top">
            <header className="header">
                <div className="container-fluid">
                    <div className="row header-desk-con">
                        <div className="col-xl-3 col-lg-2">
                            <div className="header__logo">
                                <Link className="logo" to="/"><img src={close} alt="close" style={{ height: "2rem" }} onClick={handleCloseProducts} /></Link>
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-7">
                            <nav className="header__menu">
                                <ul>
                                    {/* <h4 className="heading"><Link to="/" style={{ color: "black" }}>casco</Link></h4> */}
                                    <Link className="logo" to="/"><img src={middleLogo} alt="Logo" style={{ height: "3.5rem" }} /></Link>
                                </ul>
                            </nav>
                        </div>
                        <div className="col-lg-3">
                            <div className="header__right">
                                <h6 className="products" onClick={handleCloseProducts}>Products</h6>
                            </div>
                        </div>
                    </div>
                    <div className="canvas__open">
                        <i className="fas fa-bars" onClick={handleShow}></i>
                    </div>
                </div>
            </header>
            <NavRow handleCloseProducts={handleCloseProducts} />
        </Offcanvas>


        {/* custom */}
        <Offcanvas show={show} onHide={handleClose} variant="dark">
            <Offcanvas.Header closeButton style={{ backgroundColor: "#fff" }}>
                <Offcanvas.Title>
                    <Link className="logo" to="/"><img src={logo} alt="Logo" style={{ height: "2.5rem" }} /></Link>
                </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body style={{ backgroundColor: "#212529" }} className="custom-menu-bar">
                <NavRow handleCloseProducts={handleCloseProducts} />
            </Offcanvas.Body>
        </Offcanvas>

       <style jsx>
           {
               `
                .custom-menu-bar .navRow-container{
                    flex-direction: column;
                }
                .offcanvas-header{
                    padding: 0.5rem 1rem;
                }
               `
           }
       </style>

    </>
    )
}

export default Header
