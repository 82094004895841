import React, { useState, useContext, useEffect } from 'react'
import db from "./firebase"
import { collection, addDoc, getDocs } from 'firebase/firestore';
import FileSaver from 'file-saver'
import XLSX from 'xlsx'


const AppContext = React.createContext()

const AppProvider = ({ children }) => {

    const contactforms = [];
    const newsletters = [];

    const getContactformsData = async () => {
        const querySnapshot = await getDocs(collection(db, "contact-forms"));
        querySnapshot.forEach((doc) => {
            console.log(doc.id, '=>', doc.data());
            contactforms.push(doc.data())
        });
    }

    const getNewslettersData = async () => {
        const querySnapshot = await getDocs(collection(db, "newsletters"));
        querySnapshot.forEach((doc) => {
            console.log(doc.id, '=>', doc.data());
            newsletters.push(doc.data())
        });
    }

    useEffect(() => {
        getContactformsData();
        getNewslettersData();
    }, [])

    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

    const fileExtension = '.xlsx';

    const exportToCSV = (csvData, fileName) => {
        const ws = XLSX.utils.json_to_sheet(csvData)
        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] }
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
        const data = new Blob([excelBuffer], { type: fileType })
        FileSaver.saveAs(data, fileName + fileExtension)
    }

    return <AppContext.Provider value={{ contactforms, newsletters, exportToCSV }}>{children}</AppContext.Provider>
}
// make sure use
export const useGlobalContext = () => {
    return useContext(AppContext)
}

export { AppContext, AppProvider }