import React, { useState, useEffect } from 'react';
import HomePage from "./Pages/HomePage";
import ProductsPage from "./Pages/ProductsPage";
import ProductDetailsPage from "./Pages/ProductDetailsPage";
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import Contact from "./Pages/Contact";
import Login from "./Pages/Login";
import Admin from "./Pages/Admin";
import SingleCategoryPage from "./Pages/SingleCategoryPage";
import { getAuth } from "firebase/auth";
const App = () => {
  const [user, setUser] = useState(null)
  useEffect(() => {
    let getUser = localStorage.getItem('user');
    setUser(getUser)

    const auth = getAuth();
    const user = auth.currentUser;
    if (user !== null) {
      // The user object has basic properties such as display name, email, etc.
      const displayName = user.displayName;
      const email = user.email;
      const photoURL = user.photoURL;
      const emailVerified = user.emailVerified;
      console.log('user exist')
    
      // The user's ID, unique to the Firebase project. Do NOT use
      // this value to authenticate with your backend server, if
      // you have one. Use User.getToken() instead.
      const uid = user.uid;
    }
  })
  return <Router>
    {
      console.log('user=>', user)
    }
    <Switch>
      {/* {console.log('login')} */}
    
      <Route path="/home" exact>
        <HomePage />
      </Route>
      <Route path="/products/:category" exact>
        {/* <SingleCategoryPage /> */}
        <ProductsPage />
      </Route>
      <Route path="/admin" exact>
        <Admin />
      </Route>
      <Route path="/login" exact>
        <Login />
      </Route>
      <Route path="/home" exact>
        <HomePage />
      </Route>
      <Route path="/products" exact>
        <ProductsPage />
      </Route>
      <Route path="/productdetails/:id" exact>
        <ProductDetailsPage />
      </Route>
      <Route path="/contact" exact>
        <Contact />
      </Route>
      <Route path="/" exact>
        <HomePage />
      </Route>
      {/* <Route path="/" exact>
        <Login />
      </Route> */}
    </Switch>
  </Router>
};

export default App;