/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'

import { a } from 'react-router-dom'

const Instagram = () => {

    const insta1 = require("../img/instagram/insta-7.jpg").default
    const insta2 = require("../img/instagram/insta-2.jpg").default
    const insta3 = require("../img/instagram/insta-3.jpg").default
    const insta4 = require("../img/instagram/insta-5.jpg").default
    const insta5 = require("../img/instagram/insta-4.jpg").default
    const insta6 = require("../img/instagram/insta-6.jpg").default

    return (
        <div class="instagram">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-lg-2 col-md-4 col-sm-4 p-0">
                        <div
                            class="instagram__item set-bg"
                            style={{
                                backgroundImage: `url(${insta1})`,
                            }}
                        >
                            <div class="instagram__text">
                                <i class="fab fa-instagram"></i>
                                <a href="https://www.instagram.com/sonoxkitchenware/" target="_blank">@ sonoxkitchenware</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-2 col-md-4 col-sm-4 p-0">
                        <div
                            class="instagram__item set-bg"
                            style={{
                                backgroundImage: `url(${insta2})`,
                            }}
                        >
                            <div class="instagram__text">
                                <i class="fab fa-instagram"></i>
                                <a href="https://www.instagram.com/sonoxkitchenware/" target="_blank">@ sonoxkitchenware</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-2 col-md-4 col-sm-4 p-0">
                        <div
                            class="instagram__item set-bg"
                            style={{
                                backgroundImage: `url(${insta3})`,
                            }}
                        >
                            <div class="instagram__text">
                                <i class="fab fa-instagram"></i>
                                <a href="https://www.instagram.com/sonoxkitchenware/" target="_blank">@ sonoxkitchenware</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-2 col-md-4 col-sm-4 p-0">
                        <div
                            class="instagram__item set-bg"
                            style={{
                                backgroundImage: `url(${insta4})`,
                            }}
                        >
                            <div class="instagram__text">
                                <i class="fab fa-instagram"></i>
                                <a href="https://www.instagram.com/sonoxkitchenware/" target="_blank">@ sonoxkitchenware</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-2 col-md-4 col-sm-4 p-0">
                        <div
                            class="instagram__item set-bg"
                            style={{
                                backgroundImage: `url(${insta5})`,
                            }}
                        >
                            <div class="instagram__text">
                                <i class="fab fa-instagram"></i>
                                <a href="https://www.instagram.com/sonoxkitchenware/" target="_blank">@ sonoxkitchenware</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-2 col-md-4 col-sm-4 p-0">
                        <div
                            class="instagram__item set-bg"
                            style={{
                                backgroundImage: `url(${insta6})`,
                            }}
                        >
                            <div class="instagram__text">
                                <i class="fab fa-instagram"></i>
                                <a href="https://www.instagram.com/sonoxkitchenware/" target="_blank">@ sonoxkitchenware</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Instagram
