import React, {
    useEffect, useState
} from 'react'
import { Card, Container, Row } from 'react-bootstrap'
import { Link,useHistory } from 'react-router-dom'
import { collection, addDoc, getDocs } from 'firebase/firestore';
import db from "../firebase"


const NavRow = ({ handleCloseProducts }) => {
    const history = useHistory();
    const product1 = require("../img/Navbar/Kitchen_tools.jpg").default
    const product2 = require("../img/Navbar/TM_Cutlery_Banner_New.jpg").default
    const product3 = require("../img/Navbar/cookware.png").default
    const product4 = require("../img/Navbar/vegGraters.jpg").default



    const [categories, setCategories] = useState([])
    const category = []
    useEffect(() => {
        const getCategory = async () => {
            const querySnapshot = await getDocs(collection(db, "category"));
            querySnapshot.forEach((doc) => {
                console.log(doc.id, '=>', doc.data());
                category.push(doc.data())
            });
            setCategories(category)
        }
        getCategory();
    }, [])


    return (<>
        {/* <Container >
             <Row style={{ width: "100%" }}> */}
        <div className="navRow-container">
            {console.log("categories=>", categories)}
            {/* {
            categories?.map((item,key)=>{
                return (

                )
            })
        } */}
            <Link to="/products/lunchbox" >
                <Card className="nav-card" style={{  border: "#000" }} variant="dark" bg="dark" onClick={handleCloseProducts}>
                    <Card.Img variant="top" src={product3} className="navRow-image" />
                    <Card.Body style={{ backgroundColor: "#000" }}>
                        <Card.Title style={{ color: "white" }}>Lunchbox</Card.Title>
                    </Card.Body>
                </Card>
            </Link>

            <Link to="/products/kitchentools" >
                <Card className="nav-card" style={{  border: "#000" }} bg="dark" onClick={handleCloseProducts} >
                    <Card.Img variant="top" src={product1} className="navRow-image" />
                    <Card.Body style={{ backgroundColor: "#000" }}>
                        <Card.Title style={{ color: "white" }}>KITCHEN TOOLS</Card.Title>
                    </Card.Body>
                </Card>
            </Link>


            <Link to="/products/veggraters">
                <Card className="nav-card" style={{  border: "#000" }} variant="dark" bg="dark" onClick={handleCloseProducts}>
                    <Card.Img variant="top" src={product4} className="navRow-image" />
                    <Card.Body style={{ backgroundColor: "#000" }}>
                        <Card.Title style={{ color: "white" }}>VEG GRATERS</Card.Title>
                    </Card.Body>
                </Card>
            </Link>

            <Link to="/products/cutlery">
                <Card className="nav-card" style={{  border: "#000" }} variant="dark" bg="dark" onClick={handleCloseProducts}>
                    <Card.Img variant="top" src={product2} className="navRow-image" />
                    <Card.Body style={{ backgroundColor: "#000" }}>
                        <Card.Title style={{ color: "white" }}>CUTLERY</Card.Title>


                    </Card.Body>
                </Card>
            </Link>

        </div>
        {/* </Row>
         </Container> */}</>
    )
}

export default NavRow
