import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Footer from '../Components/Footer'
import Header from '../Components/Header'
import Instagram from '../Components/Instagram'


const HomePage = () => {
    const category1 = require("../../src/img/categories/category-1.jpg").default
    // const category3 = require("../../src/img/categories/category-3.jpg").default
    // const category4 = require("../../src/img/categories/category-4.jpg").default
    // const category5 = require("../../src/img/categories/category-5.jpg").default

    // const category2 = require("../img/cookware.png").default
    // const category3 = require("../img/Kitchen_tools.jpg").default
    // const category4 = require("../img/BXS_0077.JPG").default
    // const category5 = require("../img/TM_Cutlery_Banner_New.jpg").default

    const category2 = require("../img/homeSection/cookware.png").default
    const category3 = require("../img/homeSection/Kitchen_tools.jpg").default
    const category4 = require("../img/homeSection/vegGraters.jpg").default
    const category5 = require("../img/homeSection/TM_Cutlery_Banner_New.jpg").default

    const Carouselcategory2 = require("../img/Carousel/cookware.png").default
    const Carouselcategory3 = require("../img/Carousel/Kitchen_tools.jpg").default
    const Carouselcategory4 = require("../img/Carousel/vegGraters.jpg").default
    const Carouselcategory5 = require("../img/Carousel/TM_Cutlery_Banner_New.jpg").default
    const Carouselcategory6 = require("../img/Carousel/Tawa.jpg").default


    const [imgIndex, setImgIndex] = useState(0)
    const [imgList, setImgList] = useState([Carouselcategory2, Carouselcategory3, Carouselcategory4, Carouselcategory5,Carouselcategory6])


    useEffect(() => {
        // setInterval(() => {
        //     setImgIndex((old)=> old+1 == imgList.length ? 0 : old + 1)
        // }, 3000);
    }, [])

    return (
        <>
            <Header />

            {/* ------------------------------------------------------------------------------------------------- */}

            <section class="categories">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-lg-6 col-md-12 col-sm-12 p-0">
                            {/* <div
                                class="categories__item categories__large__item set-bg"
                                style={{
                                    backgroundImage: `url(${category1})`,
                                    alignItems:"center"
                                }}

                            >
                                <img src="./img/categories/category-1.jpg" alt="" />
                                <div class="categories__text">
                                    <h1>All Products</h1>
                                    <p>
                                        Sitamet, consectetur adipiscing elit, sed do eiusmod tempor
                                        incidid-unt labore edolore magna aliquapendisse ultrices
                                        gravida.
                                    </p>
                                    <Link to="/products/all">View Products</Link>
                                </div>
                            </div> */}


                            <div id="carouselExampleIndicators" className="container-height carousel slide" data-ride="carousel" style={{ marginTop:"10px" }}>
                                <ol className="carousel-indicators">
                                    {
                                        imgList.map((img, index) => (<li data-target="#carouselExampleIndicators" onClick={() => setImgIndex(index)} data-slide-to="0" className={index == imgIndex && "active"} />))
                                    }
                                </ol>
                                <div className="container-height carousel-inner">
                                    {
                                        imgList.map((img, index) => (<div className={`container-height carousel-item ${index == imgIndex ? "active" : ""}`}>
                                            <img className="container-height d-block w-100 " src={img} alt="First slide" style={{ objectFit: index != 3 ? "cover" : "contain" }} />
                                            <div class="carousel-caption d-flex justify-center d-md-block" style={{ alignItems:"center",justifyContent:"center" }}>
                                                <div className="categories__text" style={{ background:"#0000004f",padding: "1.5rem",width:"fit-content" }}>
                                                    <h4 style={{ color:"white",marginBottom:"0.5rem" }}>All Products</h4>
                                                    {/* <p>
                                                        Sitamet, consectetur adipiscing elit, sed do eiusmod tempor
                                                        incidid-unt labore edolore magna aliquapendisse ultrices
                                                        gravida.
                                                    </p> */}
                                                    <Link to="/products/all" style={{ color:"white" }}>View Products</Link>
                                                </div>
                                            </div>
                                        </div>))
                                    }

                                    {/* 
                                    <div style={{ height: "600px" }} className="carousel-item active">
                                        <img className="d-block w-100" src={category3} alt="Second slide" />
                                    </div>
                                    <div style={{ height: "600px" }} className="carousel-item">
                                        <img className="d-block w-100" src={category3} alt="Third slide" />
                                    </div>

                                    <div style={{ height: "600px" }} className="carousel-item">
                                        <img className="d-block w-100" src={category4} alt="Third slide" />
                                    </div> */}


                                    {/* <div className="categories__item">
                                    <div className="categories__text">
                                        <h1>All Products</h1>
                                        <p>
                                            Sitamet, consectetur adipiscing elit, sed do eiusmod tempor
                                            incidid-unt labore edolore magna aliquapendisse ultrices
                                            gravida.
                                        </p>
                                        <Link to="/products/all">View Products</Link>
                                    </div>
                                    </div> */}
                                </div>
                                <a onClick={() => setImgIndex((old) => old <= 0 ? imgList.length - 1 : old - 1)} className="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
                                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                    <span className="sr-only">Previous</span>
                                </a>
                                <a onClick={() => setImgIndex((old) => old + 1 == imgList.length ? 0 : old + 1)} className="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
                                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                    <span className="sr-only">Next</span>
                                </a>
                            </div>


                        </div>
                        <div class="col-lg-6">
                            <div class="row">
                                <div class="col-lg-6 col-md-6 col-sm-6 p-0">
                                    <div
                                        class="categories__item set-bg"
                                        style={{
                                            backgroundImage: `url(${category2})`,
                                        }}

                                    >
                                        <div class="categories__text">
                                            <h4 style={{ color:"white" }}>LunchBox</h4>
                                            {/* <p>358 items</p> */}
                                            <Link to="/products/lunchbox" style={{ color:"white" }}>View Products</Link>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6 p-0">
                                    <div
                                        class="categories__item set-bg"
                                        style={{
                                            backgroundImage: `url(${category3})`,
                                        }}

                                    >
                                        <div class="categories__text">
                                            <h4>Kitchen Tools</h4>
                                            {/* <p>273 items</p> */}
                                            <Link to="/products/kitchentools">View Products</Link>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6 p-0">
                                    <div
                                        class="categories__item set-bg"
                                        style={{
                                            backgroundImage: `url(${category4})`,
                                        }}

                                    >
                                        <div class="categories__text">
                                            <h4>Veg Graters</h4>
                                            {/* <p>159 items</p> */}
                                            <Link to="/products/veggraters">View Products</Link>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6 p-0">
                                    <div
                                        class="categories__item set-bg"
                                        style={{
                                            backgroundImage: `url(${category5})`,
                                            backgroundPosition:"left"
                                        }}

                                    >
                                        <div class="categories__text">
                                            <h4>Cutlery</h4>
                                            {/* <p>792 items</p> */}
                                            <Link to="/products/cutlery">View Products</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* ----------------------------------------------------------------------------------------------------- */}
            <section className="brands">
                <div className="words">

                    <div className="wordsheading">
                        <h1 className="brand-title">our brands</h1>
                    </div>
                    <div className="company row">
                        <div className="com1 col-lg-3 col-sm-3">
                            {/* <i class="fas fa-crown logo com1logo"></i> */}
                            <img src={require("../img/logo/Casco Logo.png").default} style={{ height: "110px", width: "140px", objectFit: "contain", marginBottom: "1rem" }} />
                            {/* <h1>casco</h1> */}
                            {/* <p>Everything your Inner Chef Desires</p> */}
                        </div>
                        <div className="com1 col-lg-3 col-sm-3">
                            {/* <i class="fas fa-crown logo com1logo"></i> */}
                            <img src={require("../img/Sonox Logo _ Premium.png").default} style={{ height: "110px", width: "140px", objectFit: "contain", marginBottom: "1rem" }} />
                            {/* <h1>casco</h1> */}
                            {/* <p>Everything your Inner Chef Desires</p> */}
                        </div>
                        <div className="com2  col-lg-3 col-sm-3">
                            <img src={require("../img/logo/table-manner.png").default} style={{ height: "110px", width: "200px", objectFit: "contain", marginBottom: "1rem" }} />
                            {/* <h1>Table Manners</h1> */}
                            {/* <p>Exrtraordinary quality for Extraordinary people</p> */}
                        </div>
                    </div>
                </div>
                <Instagram />
            </section>


            {/* ----------------------------------------------------------------------------------------------------- */}

            <Footer />
        </>
    )
}

export default HomePage
